<template>
  <div>
    <section class="lightColor">
      <div class="pl-15 mb-0 ml-7 mr-5">
        <p class="font-weight pt-2 font-size-20">Tell us About Your Project</p>
        <p class="font-size-12">Share your requirments</p>
      </div>
      <b-row class="pt-2 p-5 mt-0 ml-5 mr-5">
        <b-col cols="12" md="3">
          <v-select :items="items" outlined dense class="m-2"></v-select>
        </b-col>
        <b-col cols="12" md="3">
          <v-select :items="items" outlined dense class="m-2"></v-select>
        </b-col>
        <b-col cols="12" md="3">
          <b-button variant="dark" size="sm" class="p-2 w-100 border-radius-8">
            Schedule Your Consultation
          </b-button>
        </b-col>
      </b-row>
    </section>
    <section>
      <b-row class="p-5 ml-5 mr-5">
        <div class="text-center">
          <span class="section-title font-size-12 pb-5"> How it Works?</span>
          <h3 class="section-subtitle font-size-25 pt-5 section3-title">
            How it Works?
          </h3>
        </div>
        <b-row class="mt-3">
          <b-col cols="12" sm="4">
            <b-card class="border-0 hovereffect">
              <div class="p-3 text-center section3-card">
                <b-img
                  src="image/illustrator1.png"
                  alt="Image 1"
                  height="300"
                  class="w-100"
                ></b-img>
                <p class="mt-8 font-size-14">
                  Share your project <br />requirments
                </p>
              </div>
            </b-card>
          </b-col>
          <b-col cols="12" sm="4">
            <b-card class="border-0 hovereffect">
              <div class="p-3 text-center section3-card">
                <b-img
                  class="w-100"
                  src="image/illustrator2.png"
                  alt="Image 2"
                  height="300"
                ></b-img>
                <p class="mt-8 font-size-14">
                  We select for you as<br />your requirments
                </p>
              </div>
            </b-card>
          </b-col>
          <b-col cols="12" sm="4">
            <b-card class="border-0 hovereffect">
              <div class="p-3 text-center section3-card">
                <b-img
                  class="w-100"
                  src="image/illustrator3.png"
                  alt="Image 3"
                  height="300"
                ></b-img>
                <p class="mt-8 font-size-14">
                  Get matched<br />
                  for you
                </p>
              </div>
            </b-card>
          </b-col>
        </b-row>
        <b-row>
          <b-col class="text-center">
            <b-button variant="dark" size="sm" class="border-radius-8"
              >Start Discussion</b-button
            >
          </b-col>
        </b-row>
      </b-row>
    </section>
  </div>
</template>
<script>
export default {
  name: "tellus",
  data() {
    return {
      modalShow: false,
      selected: null,
      options: [
        { value: null, text: "Types Of Services" },
        { value: "a", text: "This is First option" },
        { value: "b", text: "Selected Option" },
      ],
      selected1: null,
      options1: [
        { value: null, text: "Youre Budget" },
        { value: "a", text: "This is First option" },
        { value: "b", text: "Selected Option" },
      ],
    };
  },
};
</script>